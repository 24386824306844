
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ruDeclension } from '@/helpers/ruDeclension';

@Component
export default class CaseCountdown extends Vue {
  @Prop(Date) readonly date: Date;

  now: number = Math.trunc(new Date().getTime() / 1000);
  isFinished: Boolean = false;

  mounted() {
    const _self = this;
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
      if (!this.isFinished && this.calculatedDate - this.now <= 0) {
        _self.isFinished = true;
        _self.$emit('onFinish');
      }
    }, 1000);
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get calculatedDate() {
    return Math.trunc(Date.parse(this.date.toString()) / 1000);
  }

  get secondCount() {
    return this.calculatedDate - this.now;
  }

  get seconds() {
    if (this.secondCount < 0) return 0;
    return this.secondCount % 60;
  }

  get minutes() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60) % 60;
  }

  get hours() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60 / 60) % 24;
  }

  get days() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60 / 60 / 24);
  }

  twoDigits(val: number) {
    return val.toString().length <= 1 ? '0' + val.toString() : val.toString();
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
