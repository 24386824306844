var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cases-list"},[(_vm.bonusCases.length)?_c('div',[_c('TextHeader',{attrs:{"level":2,"text":"Для вас","division":""}}),_c('div',{staticClass:"cases-grid"},_vm._l((_vm.bonusCases),function(ca){return _c('Case',{key:ca.id,attrs:{"_case":{
          ...ca,
          ...{
            isBonusCase: _vm.isBonusCase(ca.id),
            oldPrice: _vm.getCaseValuablePrice(ca.price, ca.id),
          },
        }}})}),1)],1):_vm._e(),_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,class:{
      'cases-list__category': true,
      '--mana': _vm.isManaCase(category.id),
    }},[(_vm.isBannerCategory(category.id))?_c('PromoBanner',{attrs:{"type":5}}):_c('div',[_c('TextHeader',{attrs:{"level":2,"text":category.name,"division":"","id":'cases-' + category.id}}),(_vm.isGiveaway(category.id))?_c('div',[_c('div',[_c('Giveaways')],1)]):_vm._e(),_c('div',{staticClass:"cases-grid"},_vm._l((category.cases),function(ca){return _c('Case',{key:ca.id,attrs:{"_case":{
            ...ca,
            ...{
              isManaCase: _vm.isManaCase(category.id),
              isPremiumCase: _vm.isPremiumCase(category.id),
              isBonusCase: _vm.isBonusCase(ca.id),
              isFarmCase: _vm.isFarmCase(category.id),
              oldPrice: _vm.getCaseValuablePrice(ca.price, category.id),
            },
          }}})}),1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }